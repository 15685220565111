import logo from '@assets/images/logo/logo.png';
import travelOfficeLogo from '@assets/images/logo/logo.svg';
import login from '@assets/images/pages/login-v2.svg';
import forgotPassword from '@assets/images/pages/forgot-password-v2.svg';
import errorPage from '@assets/images/pages/error.svg';

const Images = {
  logo,
  login,
  forgotPassword,
  errorPage,
  travelOfficeLogo,
};

export default Images;
